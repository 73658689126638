import "./bootstrap";
import $ from "jquery";
import Alpine from "alpinejs";
import "slick-carousel";
import "lazysizes";
import { Fancybox } from "@fancyapps/ui";
import { Swal } from "sweetalert2";
import AOS from "aos";
import "flowbite";

window.$ = window.jQuery = $;
window.AOS = AOS;
window.Fancybox = Fancybox;
window.Swal = Swal;
window.Alpine = Alpine;
// Initialise jQuery
$(document).ready(function () {
    // Any jQuery scripts...
    AOS.init({
        // once: true,
    });

    Fancybox.bind("[data-fancybox]", {});
    Alpine.start();
});
